// Area Chart
.chart-area {
    position: relative;
    height: 30rem;
    width: 100%;
@include media-breakpoint-up(md) {
    height: 30rem;
}
}

// Bar Chart
   .chart-bar {
       position: relative;
       height: 10rem;
       width: 100%;
@include media-breakpoint-up(md) {
    height: 20rem;
}
}

// Pie Chart
   .chart-pie {
       position: relative;
       height: 15rem;
       width: 100%;
@include media-breakpoint-up(md) {
    height: calc(20rem - 43px) !important;
}
}

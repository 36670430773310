.main {
	width: 100vw;
	height: 100vh;
	z-index: 25;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	h2 {
		margin: 0;
		color: white;
	}
	p {
		color: white;
	}
	img {
		width: 100px;
	}
}

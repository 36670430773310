.search_container {
	z-index: 500;
	width: 50vw;
}
.search_field {
	width: 50vw;
}

.search_item {
	cursor: pointer;
	padding: 5px;
}

.search_item:hover {
	background-color: rgba(0, 0, 0, 0.03);
}

.term_and_cond:hover {
	color: rgba(78, 115, 223, 0.5);
	text-decoration: underline;
	cursor: pointer;
}

.stored_elements {
	margin-bottom: 10px;
	li {
		text-decoration: none;
	}
}

.indicator_wrapper {
	width: 100%;
	height: 30px;
	position: relative;
	margin: 50px 0;
	.indicator_container {
		width: 100%;
		height: 100%;
		position: absolute;
		border-radius: 3px;
		overflow: hidden;
		color: white;
		text-align: center;
		outline: 1px solid rgba(0, 0, 0, 0.4);
		.indicator_background {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			display: flex;
		}
		.indicator_background > div {
			height: 100%;
			width: 50%;
			background-color: rgb(231, 74, 59);
		}

		.indicator_background > div:nth-child(2) {
			background-color: rgb(249, 220, 4);
		}

		.indicator_front {
			width: 60%;
			height: 100%;
			position: absolute;
			left: 20%;
			top: 0;
			z-index: 2;
			// border: 2px solid rgba(0, 0, 0, 0.8);
			border-top: 0;
			border-bottom: 0;
		}
		.indicator_separator {
			width: 2px;
			height: 100%;
			top: 0;
			background: rgb(50, 50, 50);
			position: absolute;
			z-index: 3;
		}
	}
	.indicator {
		top: 100%;
		width: 35px;
		height: 20px;
		position: absolute;
	}
	.min {
		left: calc(20% - 10px);
	}
	.max {
		left: calc(80% - 10px);
	}
	.actual_position_indicator {
		position: absolute;
		top: -40px;
		width: 55px;
		min-width: 35px;
		padding: 2px;
		border: 1px solid rgba(0, 0, 0, 0.4);
		border-radius: 1px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
	}
	.actual_position_indicator::after {
		content: ">";
		color: rgba(0, 0, 0, 0.6);
		top: 75%;
		left: 42%;
		transform: rotate(90deg);
		position: absolute;
	}
}
